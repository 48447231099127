import { Injectable } from "@angular/core";

import { Action, Selector, State, StateContext } from "@ngxs/store";
import { patch } from "@ngxs/store/operators";

import { UpdateClient } from "@state-management/actions";

import { Client } from "@models/clients";

export interface ClientStateModel extends Client { };

const CLIENT_STATE_DEFAULT: ClientStateModel = null;

@State<ClientStateModel>({
    name: 'client',
    defaults: CLIENT_STATE_DEFAULT
})

@Injectable()
export class ClientState {
    @Selector()
    static getClient(state: ClientStateModel) {
        return state;
    }

    @Action(UpdateClient)
    public updateClient(ctx: StateContext<ClientStateModel>, action: UpdateClient) {
        ctx.setState(
            patch(action.client)
        );
    }

}