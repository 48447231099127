export function Countries() {
    return [
        // 'Albania',
        // 'Algeria',
        // 'Angola',
        // 'Antigua and Barbuda',
        // 'Argentina', 'Armenia',
        // 'Aruba',
        {
            name: 'Australia',
            locale: 'en_AU'
        },
        // 'Austria',
        // 'Azerbaijan',
        // 'Bahamas',
        // 'Bahrain',
        // 'Bangladesh',
        // 'Belarus',
        // 'Belgium',
        // 'Belize',
        // 'Benin',
        // 'Bolivia',
        // 'Bosnia and Herzegovina',
        // 'Botswana',
        // 'Brazil',
        // 'Bulgaria',
        // 'Burkina Faso',
        // 'Cambodia',
        // 'Cameroon',
        // 'Canada',
        // 'Cape Verde',
        // 'Chile',
        // 'China',
        // 'Colombia',
        // 'Costa Rica',
        // 'Croatia',
        // 'Cuba',
        // 'Cyprus',
        // 'Czech Republic',
        // 'Côte d’Ivoire',
        // 'Denmark',
        // 'Dominican Republic',
        // 'Ecuador',
        // 'Egypt',
        // 'El Salvador',
        // 'Estonia',
        // 'Fiji',
        // 'Finland',
        // 'France',
        // 'Gabon',
        // 'Germany',
        // 'Ghana',
        // 'Greece',
        // 'Guatemala',
        // 'Guinea-Bissau',
        // 'Haiti',
        // 'Honduras',
        // 'Hong Kong',
        // 'Hungary',
        // 'Iceland',
        // 'India',
        // 'Indonesia',
        // 'Iran',
        {
            name: 'Ireland',
            locale: 'en_IE'
        },
        // 'Israel',
        // 'Italy',
        // 'Jamaica',
        // 'Japan',
        // 'Jordan',
        // 'Kazakhstan',
        // 'Kenya',
        // 'Kuwait',
        // 'Kyrgyzstan',
        // 'Laos',
        // 'Latvia',
        // 'Lebanon',
        // 'Liechtenstein',
        // 'Lithuania',
        // 'Luxembourg',
        // 'Macau',
        // 'Macedonia',
        // 'Malaysia',
        // 'Mali',
        // 'Malta',
        // 'Mauritius',
        // 'Mexico',
        // 'Moldova',
        // 'Morocco',
        // 'Mozambique',
        // 'Myanmar',
        // 'Namibia',
        // 'Nepal',
        // 'Netherlands',
        // 'Netherlands Antilles',
        // 'New Zealand',
        // 'Nicaragua',
        // 'Niger',
        // 'Nigeria',
        // 'Norway',
        // 'Oman',
        // 'Pakistan',
        // 'Panama',
        // 'Papua New Guinea',
        // 'Paraguay',
        // 'Peru',
        // 'Philippines',
        // 'Poland',
        // 'Portugal',
        // 'Qatar',
        // 'Romania',
        // 'Russia',
        // 'Rwanda',
        // 'Saudi Arabia',
        // 'Senegal',
        // 'Serbia',
        // 'Singapore',
        // 'Slovakia',
        // 'Slovenia',
        // 'South Africa',
        // 'South Korea',
        // 'Spain',
        // 'Sri Lanka',
        // 'Sudan',
        {
            name: 'Sweden',
            locale: 'sv_SE'
        },
        // 'Switzerland',
        // 'Taiwan',
        // 'Tajikistan',
        // 'Tanzania',
        // 'Thailand',
        // 'Togo',
        // 'Trinidad and Tobago',
        // 'Tunisia',
        // 'Turkey',
        // 'Turkmenistan',
        // 'Uganda',
        // 'Ukraine',
        // 'United Arab Emirates',
        {
            name: 'Scotland',
            locale: 'en_GB'
        },
        {
            name: 'United Kingdom',
            locale: 'en_GB'
        },
        {
            name: 'United States',
            locale: 'en_US'
        },
        // 'Uruguay',
        // 'Uzbekistan',
        // 'Venezuela',
        // 'Vietnam',
        // 'Yemen',
        // 'Zambia',
        // 'Zimbabwe'
    ];
}
