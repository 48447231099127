export class UpdateImportExportPageSize {
    static readonly type = '[Import Export] Update Import Export Page Size';
    constructor(public pageSize: number) { }
}

export class UpdateImportExportSearchQuery {
    static readonly type = '[Import Export] Update Import Export Search Query';
    constructor(public searchQuery: string) { }
}

export class UpdateImportExportActivePage {
    static readonly type = '[Import Export] Update Import Export Active Page';
    constructor(public activePage: number) { }
}

export class UpdateImportExportSort {
    static readonly type = '[Import Export] Update Import Export Sort';
    constructor(public sort: { field: string, order: 1 | -1 }) { }
}

export class ResetImportExportState {
    static readonly type = '[Import Export] Reset Import Export State';
    constructor() { }
}
