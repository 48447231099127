export const FlagDates = () => {
    return [
        {
            name: 'Past',
            value: 0
        },
        {
            name: '7 Days',
            value: 7
        },
        {
            name: '30 Days',
            value: 30
        }
    ]
};