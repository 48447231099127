import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { produce } from 'immer';

import { AddExportSettings, ResetSettingsState, UpdateAuditSettings, UpdateExportSettings } from '@state-management/actions';

import { AuditSettings } from '@models/miscellaneous';

export interface SettingsStateModel {
    exports: {
        id: string;
        settings: Record<string, any>
    }[];
    audit: AuditSettings;
};

const SETTINGS_STATE_DEFAULT: SettingsStateModel = {
    exports: [],
    audit: {
        show_guidance: false,
        show_gps: false,
        show_timestamps: false,
        show_all: false
    }
};

@State<SettingsStateModel>({
    name: 'settings',
    defaults: SETTINGS_STATE_DEFAULT
})

@Injectable()
export class SettingsState {

    @Selector()
    static getExportsSettings(state: SettingsStateModel) {
        return state.exports;
    }

    @Selector()
    static getAuditSettings(state: SettingsStateModel) {
        return state.audit;
    }

    @Action(AddExportSettings)
    public addExportSettings(ctx: StateContext<SettingsStateModel>, action: AddExportSettings) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.exports.push({ id: action.reportId, settings: action.settings })
            })
        );
    }

    @Action(UpdateExportSettings)
    public updateExportSettings(ctx: StateContext<SettingsStateModel>, action: UpdateExportSettings) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                const index = draft.exports.findIndex(x => x.id === action.reportId);
                if (index !== -1) draft.exports[index].settings = action.settings
            })
        );
    }

    @Action(UpdateAuditSettings)
    public updateAuditSettings(ctx: StateContext<SettingsStateModel>, action: UpdateAuditSettings) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.audit = action.settings
            })
        );
    }

    @Action(ResetSettingsState)
    public resetSettingsState(ctx: StateContext<SettingsStateModel>, action: ResetSettingsState) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.exports = SETTINGS_STATE_DEFAULT.exports;
                draft.audit = SETTINGS_STATE_DEFAULT.audit
            })
        );
    }

}