import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { produce } from 'immer';

import * as dayjs from 'dayjs';

import { ResetAuditsState, UpdateAuditsActivePage, UpdateAuditFilters, UpdateAuditsPageSize, UpdateAuditsSearchQuery, UpdateAuditsSort, UpdateAuditsStandardReports } from '@state-management/actions';

import { AuditTypes, SortEntries } from '@data';

import { AuditFilters } from '@models/audits';
import { StandardReport } from '@models/reports';
import { TableState } from '@models/shared';

export interface AuditsStateModel extends TableState {
    filters: AuditFilters,
    standardReports: StandardReport[]
}

const AUDITS_STATE_DEFAULT: AuditsStateModel = {
    filters: {
        dates: {
            startDate: dayjs().subtract(28, 'day').startOf('day').toDate(),
            endDate: dayjs().endOf('day').toDate(),
        },
        audit_types: [AuditTypes()[0].value],
        failures: [],
        status: '',
        signOffRequired: false
    },
    standardReports: [],
    pageSize: SortEntries()[0],
    activePage: 0,
    sort: {
        field: null,
        order: null
    },
    searchQuery: '',
    filtered: false
};

@State<AuditsStateModel>({
    name: 'audits',
    defaults: AUDITS_STATE_DEFAULT
})

@Injectable()
export class AuditsState {

    @Selector()
    static getAuditsState(state: AuditsStateModel) {
        return state;
    }

    @Selector()
    static getAuditFilters(state: AuditsStateModel) {
        return state.filters;
    }

    @Action(UpdateAuditFilters)
    public updateAuditsFilters(ctx: StateContext<AuditsStateModel>, action: UpdateAuditFilters) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.filters = action.filters;
                draft.filtered = true;
            })
        );
    }

    @Action(UpdateAuditsStandardReports)
    public updateAuditsStandardReports(ctx: StateContext<AuditsStateModel>, action: UpdateAuditsStandardReports) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.standardReports = action.standardReports;
            })
        );
    }

    @Action(UpdateAuditsPageSize)
    public updateAuditsPageSize(ctx: StateContext<AuditsStateModel>, action: UpdateAuditsPageSize) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.pageSize = action.pageSize;
                draft.filtered = true;
            })
        );
    }

    @Action(UpdateAuditsSearchQuery)
    public updateAuditsSearchQuery(ctx: StateContext<AuditsStateModel>, action: UpdateAuditsSearchQuery) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.searchQuery = action.searchQuery;
                draft.filtered = true;
            })
        );
    }

    @Action(UpdateAuditsActivePage)
    public updateAuditsActivePage(ctx: StateContext<AuditsStateModel>, action: UpdateAuditsActivePage) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.activePage = action.activePage;
                draft.filtered = true;
            })
        );
    }

    @Action(UpdateAuditsSort)
    public updateAuditsSort(ctx: StateContext<AuditsStateModel>, action: UpdateAuditsSort) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.activePage = 0;
                draft.sort = action.sort;
                draft.filtered = true;
            })
        );
    }

    @Action(ResetAuditsState)
    public resetAuditsState(ctx: StateContext<AuditsStateModel>) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.filters = AUDITS_STATE_DEFAULT.filters;
                draft.standardReports = [];
                draft.pageSize = SortEntries()[0];
                draft.activePage = 0;
                draft.sort = AUDITS_STATE_DEFAULT.sort;
                draft.searchQuery = '';
                draft.filtered = false;
            })
        );
    }
}