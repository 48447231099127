import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class NetworkLoaderService {

    private loading: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor() { }

    public isLoading() {
        return this.loading.asObservable();
    }

    public show() {
        setTimeout(() => this.loading.next(true));
    }

    public hide() {
        setTimeout(() => this.loading.next(false));
    }
}
