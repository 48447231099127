import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FieldType, FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';

import { TaFormlyFieldConfig, TaFormlyOptions } from '@formly/ta-formly-types';

@Component({
	selector: 'app-ta-radio',
	standalone: true,
	imports: [CommonModule, FormsModule, ReactiveFormsModule, FormlySelectModule, FormlyModule],
	templateUrl: './ta-radio.component.html',
	styleUrl: './ta-radio.component.scss'
})
export class TaRadioComponent extends FieldType<TaFormlyFieldConfig> implements OnInit {

	public fieldOptions: TaFormlyOptions[];

	constructor() {
		super();
	}

	ngOnInit() {
		this.fieldOptions = this.props.options.map(option => ({
			...option,
			label: option.value,
			value: option.key
		}))
	}

	public clear() {
		this.formControl.reset(null);
		this.formControl.markAsDirty();
	}
}
