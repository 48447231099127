import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { produce } from 'immer';

import { ResetImportExportState, UpdateImportExportActivePage, UpdateImportExportPageSize, UpdateImportExportSearchQuery, UpdateImportExportSort } from '@state-management/actions';

import { SortEntries } from '@data';

import { TableState } from '@models/shared';

export interface ImportExportStateModel extends TableState { }

const IMPORT_EXPORT_STATE_DEFAULT: ImportExportStateModel = {
    pageSize: SortEntries()[0],
    activePage: 0,
    sort: {
        field: null,
        order: null
    },
    searchQuery: '',
    filtered: false
};

@State<ImportExportStateModel>({
    name: 'importExport',
    defaults: IMPORT_EXPORT_STATE_DEFAULT
})

@Injectable()
export class ImportExportState {

    @Selector()
    static getImportExportState(state: ImportExportStateModel) {
        return state;
    }

    @Action(UpdateImportExportPageSize)
    public updateImportExportPageSize(ctx: StateContext<ImportExportStateModel>, action: UpdateImportExportPageSize) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.pageSize = action.pageSize;
                draft.filtered = true;
            })
        );
    }

    @Action(UpdateImportExportSearchQuery)
    public updateImportExportSearchQuery(ctx: StateContext<ImportExportStateModel>, action: UpdateImportExportSearchQuery) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.searchQuery = action.searchQuery;
                draft.filtered = true;
            })
        );
    }

    @Action(UpdateImportExportActivePage)
    public updateImportExportActivePage(ctx: StateContext<ImportExportStateModel>, action: UpdateImportExportActivePage) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.activePage = action.activePage;
                draft.filtered = true;
            })
        );
    }

    @Action(UpdateImportExportSort)
    public updateImportExportSort(ctx: StateContext<ImportExportStateModel>, action: UpdateImportExportSort) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.activePage = 0;
                draft.sort = action.sort;
                draft.filtered = true;
            })
        );
    }

    @Action(ResetImportExportState)
    public resetImportExportState(ctx: StateContext<ImportExportStateModel>) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.pageSize = SortEntries()[0];
                draft.activePage = 0;
                draft.sort = IMPORT_EXPORT_STATE_DEFAULT.sort;
                draft.searchQuery = '';
                draft.filtered = false;
            })
        );
    }
}