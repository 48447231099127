
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FieldType } from '@ngx-formly/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import * as dayjs from 'dayjs';

import { get, set } from 'lodash-es';

import { FormControlFocusDirective } from '@directives/form-control-focus.directive';
import { FormControlValidationDirective } from '@directives/form-control-validation.directive';

import { TaFormlyFieldConfig } from '@formly/ta-formly-types';

@Component({
    selector: 'app-ta-date',
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, BsDatepickerModule, FormControlFocusDirective, FormControlValidationDirective],
    templateUrl: './ta-date.component.html',
    styleUrls: ['./ta-date.component.scss']
})

export class TaDateComponent extends FieldType<TaFormlyFieldConfig> implements OnInit {

    public date: Date = null;
    public bsConfigValue = { isAnimated: false, containerClass: 'theme-dark-blue', showClearButton: true, adaptivePosition: true, dateInputFormat: 'DD/MM/YYYY' };

    constructor() {
        super();
    }

    ngOnInit() {
        const value = get(this.model, this.key, null);
        if (value) {
            this.formControl.setValue(dayjs(value).toDate(), { emitEvent: false });
        }
        this.subscribeToValueChanges();
    }

    private subscribeToValueChanges() {
        this.formControl.valueChanges.subscribe((value: Date) => {
            if (value) {
                const date = dayjs(value).toISOString();
                set(this.model, this.key, date);
            } else {
                set(this.model, this.key, null);
            }
        });
    }

}
