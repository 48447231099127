import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';

import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
})

export class StorageService {

    private SECRET_KEY: string;

    constructor() {
        this.SECRET_KEY = environment.secretKey;
    }

    public saveToStorage<T>(key: string, data: T) {
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), this.SECRET_KEY).toString();
        localStorage.setItem(key, encryptedData);
    }

    public getFromStorage<T>(key: string) {
        const encryptedData = localStorage.getItem(key);
        if (encryptedData) {
            const data = CryptoJS.AES.decrypt(encryptedData, this.SECRET_KEY).toString(CryptoJS.enc.Utf8);
            return JSON.parse(data) as T;
        }
        return null;
    }

    public removeFromStorage(key: string) {
        localStorage.removeItem(key);
    }
}
