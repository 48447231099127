import { AssetFilters } from '@models/assets';
import { AssetTemplate } from '@models/templates';

export class UpdateAssetFilters {
    static readonly type = '[Assets] Update Asset Filters';
    constructor(public filters: AssetFilters) { }
}

export class UpdateAssetTypes {
    static readonly type = '[Assets] Update Asset Types';
    constructor(public assetTypes: AssetTemplate[]) { }
}

export class UpdateAssetsPageSize {
    static readonly type = '[Assets] Update Asset Page Size';
    constructor(public pageSize: number) { }
}

export class UpdateAssetsSearchQuery {
    static readonly type = '[Assets] Update Asset Search Query';
    constructor(public searchQuery: string) { }
}

export class UpdateAssetsActivePage {
    static readonly type = '[Assets] Update Asset Active Page';
    constructor(public activePage: number) { }
}

export class UpdateAssetsSort {
    static readonly type = '[Assets] Update Asset Sort';
    constructor(public sort: { field: string, order: 1 | -1 }) { }
}

export class ResetAssetsState {
    static readonly type = '[Assets] Reset Asset State';
    constructor(public loggedOut: boolean = false) { }
}
