export function ReportFormats() {
    return [
        {
            name: 'PDF',
            value: 'pdf',
        },
        {
            name: 'Excel',
            value: 'excel',
        }
    ];
}