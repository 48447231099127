
import { Component, ViewEncapsulation } from '@angular/core';
import { NgIf } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FieldType, FormlyModule } from '@ngx-formly/core';

import { FormControlFocusDirective } from '@directives/form-control-focus.directive';
import { FormControlValidationDirective } from '@directives/form-control-validation.directive';

import { TaFormlyFieldConfig } from '@formly/ta-formly-types';

@Component({
    selector: 'app-ta-input',
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, FormControlFocusDirective, FormControlValidationDirective],
    templateUrl: './ta-input.component.html',
    styleUrls: ['./ta-input.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TaInputComponent extends FieldType<TaFormlyFieldConfig> {

    constructor() {
        super();
    }

    ngOnInit() {

    }

    public integerValuesOnly(event: any) {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    }

    public decimalValuesOnly(event: any) {
        const charCode = (event.which) ? event.which : event.keyCode;
        if ((charCode < 48 || charCode > 57) && !(charCode === 46 || charCode === 108 || charCode === 110)) {
            event.preventDefault();
        } else if (charCode === 46 || charCode === 108 || charCode === 110) {
            if (this.formControl.value && ((this.formControl.value.toString()).length === 0 || (this.formControl.value.toString()).indexOf('.') !== -1)) {
                event.preventDefault();
            }
        }
    }
}
