import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FieldType, FormlyModule } from '@ngx-formly/core';

import { TaFormlyFieldConfig, TaFormlyOptions } from '@formly/ta-formly-types';
import { FormlySelectModule } from '@ngx-formly/core/select';

import { get } from 'lodash-es';

@Component({
	selector: 'app-ta-checkbox',
	standalone: true,
	imports: [CommonModule, FormsModule, ReactiveFormsModule, FormlySelectModule, FormlyModule],
	templateUrl: './ta-checkbox.component.html',
	styleUrl: './ta-checkbox.component.scss'
})
export class TaCheckboxComponent extends FieldType<TaFormlyFieldConfig> {

	public fieldOptions: TaFormlyOptions[];

	constructor() {
		super();
	}

	ngOnInit() {
		this.fieldOptions = this.props.options.map(option => ({
			...option,
			label: option.value,
			value: option.key
		}));
	}

	public onChange(optionKey: string, e: Event) {
		const element = e.target as HTMLInputElement;
		this.formControl.patchValue({ ...this.formControl.value, [optionKey]: element.checked });
	}
}
