export function BillingTypes() {
    return [
        {
            name: 'Stripe',
            value: 'stripe'
        },
        {
            name: 'Invoice',
            value: 'invoice'
        }
    ]
}