import { AssetTemplateFilters } from '@models/templates';

export class UpdateAssetTemplateFilters {
    static readonly type = '[Asset Templates] Update Asset Template Filters';
    constructor(public filters: AssetTemplateFilters) { }
}

export class UpdateAssetTemplatesPageSize {
    static readonly type = '[Asset Templates] Update Asset Templates Page Size';
    constructor(public pageSize: number) { }
}

export class UpdateAssetTemplatesSearchQuery {
    static readonly type = '[Asset Templates] Update Asset Templates Search Query';
    constructor(public searchQuery: string) { }
}

export class UpdateAssetTemplatesActivePage {
    static readonly type = '[Asset Templates] Update Asset Templates Active Page';
    constructor(public activePage: number) { }
}

export class UpdateAssetTemplatesSort {
    static readonly type = '[Asset Templates] Update Asset Templates Sort';
    constructor(public sort: { field: string, order: 1 | -1 }) { }
}

export class ResetAssetTemplatesState {
    static readonly type = '[Asset Templates] Reset Asset Templates State';
    constructor() { }
}
