import { AbstractControl } from '@angular/forms';

import { TaFormlyFieldConfig } from './ta-formly-types';

// Validators

export function RequiredValidator(formControl: AbstractControl, field: TaFormlyFieldConfig): boolean {
    if (field.ta_validators.required) {
        return formControl.value ? true : false;
    }
    return true;
}

export function CheckboxRequiredValidator(formControl: AbstractControl, field: TaFormlyFieldConfig): boolean {
    if (field.ta_validators.checkboxRequired) {
        const options = field.props.options;
        for (const option of options) {
            if (formControl.value) {
                if (formControl.value[option.key]) {
                    return true;
                }
            }
        }
        return false;
    }
    return true;
}

export function UriValidator(formControl: AbstractControl): boolean {
    if (formControl.value) {
        return /^(https:\/\/.|http:\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/.test(formControl.value);
    }
    return true;
}