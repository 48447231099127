
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FieldType } from '@ngx-formly/core';

import { FormControlFocusDirective } from '@directives/form-control-focus.directive';
import { FormControlValidationDirective } from '@directives/form-control-validation.directive';

import { TaFormlyFieldConfig } from '@formly/ta-formly-types';

@Component({
    selector: 'app-ta-toggle',
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, FormControlFocusDirective, FormControlValidationDirective],
    templateUrl: './ta-toggle.component.html',
    styleUrls: ['./ta-toggle.component.scss']
})
export class TaToggleComponent extends FieldType<TaFormlyFieldConfig> {

    constructor() {
        super();
    }
}
