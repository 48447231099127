
import { Component } from '@angular/core';

import { FieldWrapper, FormlyModule } from '@ngx-formly/core';

import { TaFormlyFieldConfig } from '@formly/ta-formly-types';

@Component({
	selector: 'app-ta-field-wrapper',
	standalone: true,
	imports: [FormlyModule],
	templateUrl: './ta-field-wrapper.component.html',
	styleUrl: './ta-field-wrapper.component.scss'
})
export class TaFieldWrapperComponent extends FieldWrapper {
	declare field: TaFormlyFieldConfig;

	constructor() {
		super();
	}
}
