import { AuditFilters } from '@models/audits';
import { StandardReport } from '@models/reports';

export class UpdateAuditFilters {
    static readonly type = '[Audits] Update Audit Filters';
    constructor(public filters: AuditFilters) { }
}

export class UpdateAuditsStandardReports {
    static readonly type = '[Audits] Update Audits Standard Reports';
    constructor(public standardReports: StandardReport[]) { }
}

export class UpdateAuditsPageSize {
    static readonly type = '[Audits] Update Audits Page Size';
    constructor(public pageSize: number) { }
}

export class UpdateAuditsSearchQuery {
    static readonly type = '[Audits] Update Audits Search Query';
    constructor(public searchQuery: string) { }
}

export class UpdateAuditsActivePage {
    static readonly type = '[Audits] Update Audits Active Page';
    constructor(public activePage: number) { }
}

export class UpdateAuditsSort {
    static readonly type = '[Audits] Update Audits Sort';
    constructor(public sort: { field: string, order: 1 | -1 }) { }
}

export class ResetAuditsState {
    static readonly type = '[Audits] Reset Audits State';
    constructor() { }
}
