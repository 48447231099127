import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

import { ApiService } from '@services';

import { AssetFormConfig } from '@models/form-configs';
import { Question, Template, Response, Section } from '@models/templates';

@Injectable()
export class ValidateTemplateService {

	private prepopulateQuestions: Record<string, { assetFormConfig: AssetFormConfig, questions: { sectionNumber: number; questionNumber: number; isNested: boolean, question: Question }[] }>;
	private invalidInputsCount: number;
	private sectionFormGroup: FormGroup;
	private templateErrors: { section: number, errors: string[] }[];
	private currentSection: number;
	private currentQuestion: number;

	constructor(
		private apiService: ApiService
	) { }

	public async validate(template: Template) {
		this.prepopulateQuestions = {};
		this.invalidInputsCount = 0;
		this.sectionFormGroup = new FormGroup({});
		this.templateErrors = [];
		this.currentSection = 1;
		this.currentQuestion = 1;

		const templateFormGroup = new FormGroup({});
		const templateTitleFormControl = new FormControl(template.title, Validators.required);
		if (templateTitleFormControl.invalid) {
			this.templateErrors.push({
				section: 0,
				errors: ['Template title has not been set.']
			});
			this.invalidInputsCount++;
		}

		for (const section of template.sections) {
			this.currentQuestion = 1;
			this.templateErrors.push({ section: this.currentSection, errors: [] });
			const sectionTitleFormControl = new FormControl(section.title, template.type === 'template' ? Validators.required : null);
			if (sectionTitleFormControl.invalid) {
				this.templateErrors[this.templateErrors.length - 1].errors.push('Section title has not been set.');
				this.invalidInputsCount++;
			}
			if (section.questions.length === 0) {
				this.templateErrors[this.templateErrors.length - 1].errors.push('Section has no questions.');
				this.invalidInputsCount++;
			}
			if (section.repeat) {
				const sectionMobileTitleFormControl = new FormControl(section.mobile_title, Validators.required);
				if (sectionMobileTitleFormControl.invalid) {
					this.templateErrors[this.templateErrors.length - 1].errors.push('Section mobile title has not been set.');
					this.invalidInputsCount++;
				}
			}
			this.checkSectionUUID(section);
			for (const question of section.questions) {
				this.checkQuestionUUID(question);
				this.checkQuestionLabel(question);
				this.checkRangeType(question);
				this.checkLinkType(question);
				await this.checkRadioCheckboxType(question);
				await this.checkRepeatQuestionType(question);
				await this.checkDatatableQuestionType(question);
				await this.checkScanType(question);
				this.checkPrepopulate(question);
				await this.checkGuidanceImages(question);
				if (question.type === 'ta-date') {
					this.checkAutoIncrement(question);
				}
				this.currentQuestion++;
			}

			this.sectionFormGroup.addControl(section.title + '_title', sectionTitleFormControl);
			templateFormGroup.addControl(section.uuid, this.sectionFormGroup);
			this.currentSection++;
		}

		this.checkPrepopulateQuestions();
		return { invalidInputsCount: this.invalidInputsCount, templateErrors: this.templateErrors };
	}

	private checkRangeType(question: Question, isNested: boolean = false) {
		if (question.type === 'ta-range') {
			const rangeMinFormControl = new FormControl(question.range.min, Validators.required);
			const rangeMaxFormControl = new FormControl(question.range.max, Validators.required);

			if (rangeMinFormControl.invalid) {
				this.templateErrors[this.templateErrors.length - 1].errors.push(`Question Type: ${question.typeText} - Minimum value has not been set in ${isNested ? 'nested question of' : ''} Question ${this.currentQuestion}.`);
				this.invalidInputsCount++;
			}

			if (rangeMaxFormControl.invalid) {
				this.templateErrors[this.templateErrors.length - 1].errors.push(`Question Type: ${question.typeText} - Maximum value has not been set in ${isNested ? 'nested question of' : ''} Question ${this.currentQuestion}.`);
				this.invalidInputsCount++;
			}

			this.sectionFormGroup.addControl(question.uuid + '_min', rangeMinFormControl);
			this.sectionFormGroup.addControl(question.uuid + '_max', rangeMaxFormControl);
		}
	}

	private checkLinkType(question: Question, isNested: boolean = false) {
		if (question.type === 'ta-link') {
			const linkFormControl = new FormControl(question.link, [Validators.required, Validators.pattern(/^(https:\/\/.|http:\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/)]);

			if (linkFormControl.invalid) {
				this.templateErrors[this.templateErrors.length - 1].errors.push(`Question Type: ${question.typeText} - URL link is not valid in ${isNested ? 'nested question of' : ''} Question ${this.currentQuestion}.`);
				this.invalidInputsCount++;
			}

			this.sectionFormGroup.addControl(question.uuid + '_link', linkFormControl);
		}
	}

	private async checkRadioCheckboxType(question: Question, isNested: boolean = false) {
		if (question.type === 'ta-radio'
			|| question.type === 'ta-checkbox'
			|| question.type === 'ta-pass-fail'
			|| question.type === 'ta-pass-fail-na') {
			for (const response of question.responses) {
				const responseFormControl = new FormControl(response.label, Validators.required);
				if (responseFormControl.invalid) {
					this.templateErrors[this.templateErrors.length - 1].errors.push(`Question Type: ${question.typeText} - Option field is not set in ${isNested ? 'nested question of' : ''} Question ${this.currentQuestion}.`);
					this.invalidInputsCount++;
				}
				this.checkQuestionResponseUUID(question, response, isNested);
				this.sectionFormGroup.addControl(response.uuid + '_response', responseFormControl);

				if (response.nestedQuestion && response.nestedQuestion.length > 0) {
					for (const nestQuestion of response.nestedQuestion) {
						this.checkQuestionUUID(nestQuestion, true);
						this.checkQuestionLabel(nestQuestion, true);
						this.checkRangeType(nestQuestion, true);
						await this.checkRadioCheckboxType(nestQuestion, true);
						await this.checkScanType(nestQuestion, true);
						this.checkPrepopulate(nestQuestion, true);
						await this.checkGuidanceImages(nestQuestion, true);
						if (nestQuestion.type === 'ta-date') {
							this.checkAutoIncrement(nestQuestion, true);
						}
					}
				}
			}
		}
	}

	private async checkRepeatQuestionType(question: Question) {
		if (question.type === 'ta-repeat-question') {
			if (question.responses[0].nestedQuestion.length === 0) {
				this.templateErrors[this.templateErrors.length - 1].errors.push(`Question Type: ${question.typeText} - Please add at least one question to Question ${this.currentQuestion}.`);
				this.invalidInputsCount++;
			} else {
				for (const nestQuestion of question.responses[0].nestedQuestion) {
					this.checkQuestionLabel(nestQuestion, true);
					this.checkRangeType(nestQuestion, true);
					await this.checkRadioCheckboxType(nestQuestion, true);
					await this.checkScanType(nestQuestion, true);
					this.checkPrepopulate(nestQuestion, true);
					await this.checkGuidanceImages(nestQuestion, true);
					if (nestQuestion.type === 'ta-date') {
						this.checkAutoIncrement(nestQuestion, true);
					}
				}
			}
		}
	}

	private async checkDatatableQuestionType(question: Question) {
		if (question.type === 'ta-datatable') {
			if (question.responses[0].nestedQuestion.length === 0) {
				this.templateErrors[this.templateErrors.length - 1].errors.push(`Question Type: ${question.typeText} - Please add at least one question to Question ${this.currentQuestion}.`);
				this.invalidInputsCount++;
			} else {
				for (const nestQuestion of question.responses[0].nestedQuestion) {
					this.checkQuestionLabel(nestQuestion, true);
					this.checkRangeType(nestQuestion, true);
					await this.checkRadioCheckboxType(nestQuestion, true);
					await this.checkScanType(nestQuestion, true);
					this.checkPrepopulate(nestQuestion, true);
					await this.checkGuidanceImages(nestQuestion, true);
					if (nestQuestion.type === 'ta-date') {
						this.checkAutoIncrement(nestQuestion, true);
					}
				}
			}
		}
	}

	private async checkScanType(question: Question, isNested: boolean = false) {
		if (question.type === 'ta-asset-scan' || question.type === 'ta-asset-select') {
			if (!this.prepopulateQuestions[question.uuid]) {
				this.prepopulateQuestions[question.uuid] = {
					assetFormConfig: null,
					questions: []
				};
			}

			const options = [
				{
					id: question.uuid + '_inputEnabled',
					checked: question.taValidators.inputEnabled
				},
				{
					id: question.uuid + '_qrCodeEnabled',
					checked: question.taValidators.qrCodeEnabled
				},
				{
					id: question.uuid + '_rfidEnabled',
					checked: question.taValidators.rfidEnabled
				},
				{
					id: question.uuid + '_uhfRfidEnabled',
					checked: question.taValidators.uhfRfidEnabled
				}
			];

			const scanOptionsFormGroup = new FormArray(options.map(option => new FormGroup({
				id: new FormControl(option.id),
				checked: new FormControl(option.checked)
			})));

			const mapOptions = (options: any[]) => {
				const selectedOptions = options.filter((option) => option.checked).map((option) => option.id);
				return selectedOptions.length ? selectedOptions : null;
			}

			const atLeastOneScanOptionSelectedFormControl = new FormControl(mapOptions(scanOptionsFormGroup.value), Validators.required);

			if (atLeastOneScanOptionSelectedFormControl.invalid) {
				this.templateErrors[this.templateErrors.length - 1].errors.push(`Question Type: ${question.typeText} - At least one form of identifying an asset needs to be selected in ${isNested ? 'nested question of' : ''} Question ${this.currentQuestion}.`);
				this.invalidInputsCount++;
			}

			if (question.asset_form_config_id) {
				if (question.asset_form_config_id !== '(any)') {
					const assetFormConfigId = question.asset_form_config_id;
					const latestAssetFormConfig: AssetFormConfig = await this.apiService.getLatestAssetFormConfig(assetFormConfigId);

					if (!this.prepopulateQuestions[question.uuid].assetFormConfig) {
						this.prepopulateQuestions[question.uuid].assetFormConfig = latestAssetFormConfig;
					}

					if (assetFormConfigId !== latestAssetFormConfig.id) {
						this.templateErrors[this.templateErrors.length - 1].errors.push(`Question Type: ${question.typeText} - Asset type '${latestAssetFormConfig.title}' references a wrong version in ${isNested ? 'nested question of' : ''} Question ${this.currentQuestion}.`);
						this.invalidInputsCount++;
					}
				}
			} else {
				this.templateErrors[this.templateErrors.length - 1].errors.push(`Question Type: ${question.typeText} - An asset type needs to be selected in ${isNested ? 'nested question of' : ''} Question ${this.currentQuestion}.`);
				this.invalidInputsCount++;
			}

			this.sectionFormGroup.addControl(question.uuid + '_scanOptions', atLeastOneScanOptionSelectedFormControl);
		}
	}

	private checkQuestionLabel(question: Question, isNested: boolean = false) {
		const questionLabelFormControl = new FormControl(question.label, Validators.required);
		if (questionLabelFormControl.invalid) {
			this.templateErrors[this.templateErrors.length - 1].errors.push(`Question Type: ${question.typeText} - Question title has not been set in ${isNested ? 'nested question of' : ''} Question ${this.currentQuestion}.`);
			this.invalidInputsCount++;
		}
		this.sectionFormGroup.addControl(question.uuid + '_label', questionLabelFormControl);
	}

	private checkPrepopulate(question: Question, isNested: boolean = false) {
		if (question.prepopulate && (question.prepopulate.prepopulateFromAsset || question.prepopulate.updateAssetFromAudit)) {
			const questionAssetScanQuestionFormControl = new FormControl(question.prepopulate.assetScanQuestionUuid, Validators.required);
			const questionAssetQuestionFormControl = new FormControl(question.prepopulate.assetQuestionUuid, Validators.required);
			if (questionAssetScanQuestionFormControl.invalid || questionAssetQuestionFormControl.invalid) {
				this.templateErrors[this.templateErrors.length - 1].errors.push(`Question Type: ${question.typeText} - Asset scan question has not been selected in ${isNested ? 'nested question of' : ''} Question ${this.currentQuestion}.`);
				this.invalidInputsCount++;
			} else {
				if (this.prepopulateQuestions[question.prepopulate.assetScanQuestionUuid]) {
					this.prepopulateQuestions[question.prepopulate.assetScanQuestionUuid].questions.push({ sectionNumber: this.currentSection, isNested, questionNumber: this.currentQuestion, question });
				}
			}
			this.sectionFormGroup.addControl(question.uuid + '_asset_scan_question', questionAssetScanQuestionFormControl);
			this.sectionFormGroup.addControl(question.uuid + '_asset_question', questionAssetQuestionFormControl);
		}
	}

	public checkAutoIncrement(question: Question, isNested: boolean = false) {
		if (question.autoIncrement && question.autoIncrement.increment) {
			const questionAutoIncrementValueFormControl = new FormControl(question.autoIncrement.value, [Validators.required, Validators.pattern("^[0-9]*$")]);
			const questionAutoIncrementUnitFormControl = new FormControl(question.autoIncrement.unit, Validators.required);
			if (questionAutoIncrementValueFormControl.invalid) {
				this.templateErrors[this.templateErrors.length - 1].errors.push(`Question Type: ${question.typeText} - Auto-increment value in date question has not been set in ${isNested ? 'nested question of' : ''} Question ${this.currentQuestion}. Please enter an integer value.`);
				this.invalidInputsCount++;
			}
			if (questionAutoIncrementUnitFormControl.invalid) {
				this.templateErrors[this.templateErrors.length - 1].errors.push(`Question Type: ${question.typeText} - An auto-increment unit needs to be selected in ${isNested ? 'nested question of' : ''} Question ${this.currentQuestion}.`);
				this.invalidInputsCount++;
			}
			this.sectionFormGroup.addControl(question.uuid + '_auto-increment_value', questionAutoIncrementValueFormControl);
			this.sectionFormGroup.addControl(question.uuid + '_auto-increment_unit', questionAutoIncrementUnitFormControl);
		}
	}

	private async checkGuidanceImages(question: Question, isNested: boolean = false) {
		if (question.guidance.images && question.guidance.images.length > 0) {
			let imageIndex = 1;
			for (const image of question.guidance.images) {
				try {
					await this.apiService.getImage(image.imageId);
				} catch (error) {
					this.templateErrors[this.templateErrors.length - 1].errors.push(`Question Type: ${question.typeText} - Guidance image number ${imageIndex} does not exist in ${isNested ? 'nested question of' : ''} Question ${this.currentQuestion}. Please delete or re-upload image.`);
					this.invalidInputsCount++;
				}
				imageIndex++;
			}
		}
	}

	private checkPrepopulateQuestions() {
		for (const { assetFormConfig, questions } of Object.values(this.prepopulateQuestions)) {
			if (assetFormConfig) {
				const assetformConfigQuestions = assetFormConfig.sections[0].questions;

				for (const { sectionNumber, questionNumber, isNested, question } of questions) {
					const assetformConfigQuestion = assetformConfigQuestions.find(q => q.fields[0].uuid === question.prepopulate.assetQuestionUuid);
					if (!assetformConfigQuestion) {
						const index = this.templateErrors.findIndex(e => e.section === sectionNumber);
						this.templateErrors[index].errors.push(`Question Type: ${question.typeText} - Prepopulate question does not exist in asset type '${question.prepopulate.assetType}' in ${isNested ? 'nested question of' : ''} Question ${questionNumber}.`);
						this.invalidInputsCount++;
					}
				}
			}
		}
	}

	private checkQuestionResponseUUID(question: Question, response: Response, isNested: boolean = false) {
		const uuidRegExpression = new RegExp(/^[0-9a-f]{32}$/g);
		if (!uuidRegExpression.test(response.uuid)) {
			this.templateErrors[this.templateErrors.length - 1].errors.push(`Question Type: ${question.typeText} - Internal error. Invalid identifier in option field in ${isNested ? 'nested question of' : ''} Question ${this.currentQuestion}.`);
			this.invalidInputsCount++;
		}
	}

	private checkSectionUUID(section: Section) {
		const uuidRegExpression = new RegExp(/^[0-9a-f]{32}$/g);
		if (!uuidRegExpression.test(section.uuid)) {
			this.templateErrors[this.templateErrors.length - 1].errors.push(`Section Internal error. Invalid identifier.`);
			this.invalidInputsCount++;
		}
	}

	private checkQuestionUUID(question: Question, isNested: boolean = false) {
		const uuidRegExpression = new RegExp(/^[0-9a-f]{32}$/g);
		if (!uuidRegExpression.test(question.uuid)) {
			this.templateErrors[this.templateErrors.length - 1].errors.push(`Question Type: ${question.typeText} - Internal error. Invalid identifier ${isNested ? 'nested question of' : ''} in Question ${this.currentQuestion}.`);
			this.invalidInputsCount++;
		}
	}
}
