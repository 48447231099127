import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';
import { produce } from 'immer';

import { ResetAssetTemplatesState, UpdateAssetTemplatesActivePage, UpdateAssetTemplateFilters, UpdateAssetTemplatesPageSize, UpdateAssetTemplatesSearchQuery, UpdateAssetTemplatesSort } from '@state-management/actions';

import { SortEntries } from '@data';

import { TableState } from '@models/shared';
import { AssetTemplateFilters } from '@models/templates';

export interface AssetTemplatesStateModel extends TableState {
    filters: AssetTemplateFilters
}

const ASSET_TEMPLATES_STATE_DEFAULT: AssetTemplatesStateModel = {
    filters: {
        status: 'active'
    },
    pageSize: SortEntries()[0],
    activePage: 0,
    sort: {
        field: null,
        order: null
    },
    searchQuery: '',
    filtered: false
};

@State<AssetTemplatesStateModel>({
    name: 'assetTemplates',
    defaults: ASSET_TEMPLATES_STATE_DEFAULT
})

@Injectable()
export class AssetTemplatesState {

    @Selector()
    static getAssetTemplatesState(state: AssetTemplatesStateModel) {
        return state;
    }

    @Selector()
    static getAssetTemplateFilters(state: AssetTemplatesStateModel) {
        return state.filters;
    }

    @Action(UpdateAssetTemplateFilters)
    public updateAssetTemplateFilters(ctx: StateContext<AssetTemplatesStateModel>, action: UpdateAssetTemplateFilters) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.filters = action.filters;
                draft.filtered = true;
            })
        );
    }

    @Action(UpdateAssetTemplatesPageSize)
    public updateAssetTemplatesPageSize(ctx: StateContext<AssetTemplatesStateModel>, action: UpdateAssetTemplatesPageSize) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.pageSize = action.pageSize;
                draft.filtered = true;
            })
        );
    }

    @Action(UpdateAssetTemplatesSearchQuery)
    public updateAssetTemplatesSearchQuery(ctx: StateContext<AssetTemplatesStateModel>, action: UpdateAssetTemplatesSearchQuery) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.searchQuery = action.searchQuery;
                draft.filtered = true;
            })
        );
    }

    @Action(UpdateAssetTemplatesActivePage)
    public updateAssetTemplatesActivePage(ctx: StateContext<AssetTemplatesStateModel>, action: UpdateAssetTemplatesActivePage) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.activePage = action.activePage;
                draft.filtered = true;
            })
        );
    }

    @Action(UpdateAssetTemplatesSort)
    public updateAssetTemplatesSort(ctx: StateContext<AssetTemplatesStateModel>, action: UpdateAssetTemplatesSort) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.activePage = 0;
                draft.sort = action.sort;
                draft.filtered = true;
            })
        );
    }

    @Action(ResetAssetTemplatesState)
    public resetAssetTemplatesState(ctx: StateContext<AssetTemplatesStateModel>) {
        ctx.setState(
            produce(ctx.getState(), draft => {
                draft.filters = ASSET_TEMPLATES_STATE_DEFAULT.filters;
                draft.pageSize = SortEntries()[0];
                draft.activePage = 0;
                draft.sort = ASSET_TEMPLATES_STATE_DEFAULT.sort;
                draft.searchQuery = '';
                draft.filtered = false;
            })
        );
    }
}