import { TypeOption } from '@ngx-formly/core/lib/models';

import { Observable } from 'rxjs';
import { get } from 'lodash-es';

import { TaCheckboxComponent } from '@formly/ta-checkbox/ta-checkbox.component';
import { TaDateComponent } from '@formly/ta-date/ta-date.component';
import { TaInputComponent } from '@formly/ta-input/ta-input.component';
import { TaRadioComponent } from './ta-radio/ta-radio.component';
import { TaTextareaComponent } from '@formly/ta-textarea/ta-textarea.component';
import { TaToggleComponent } from '@formly/ta-toggle/ta-toggle.component';
import { TaUriComponent } from './ta-uri/ta-uri.component';

import { CheckboxRequiredValidator, RequiredValidator, UriValidator } from './ta-validators';

export const FormlyComponentTypes: TypeOption[] = [
    {
        name: 'ta-checkbox',
        component: TaCheckboxComponent,
        defaultOptions: {
            validators: {
                checkboxRequired: CheckboxRequiredValidator,
            },
            validation: {
                show: true
            },
            hooks: {
                onInit: (field) => {
                    const { model, props } = field;
                    const value = get(model, field.key, null);
                    if (!value) {
                        const options = props.options;
                        if (!(options instanceof Observable)) {
                            const defaultValue: Record<string, boolean> = {};
                            for (const option of options) {
                                defaultValue[option.key] = false;
                            }
                            field.defaultValue = defaultValue;
                            field.formControl.setValue(defaultValue);
                        }
                    }
                }
            }
        },
        wrappers: ['ta-field-wrapper']
    },
    {
        name: 'ta-date',
        component: TaDateComponent,
        defaultOptions: {
            defaultValue: null,
            validators: {
                required: RequiredValidator,
            },
        },
        wrappers: ['ta-field-wrapper']
    },
    {
        name: 'ta-input',
        component: TaInputComponent,
        defaultOptions: {
            defaultValue: null,
            validators: {
                required: RequiredValidator,
            }
        },
        wrappers: ['ta-field-wrapper']
    },
    {
        name: 'ta-number',
        extends: 'ta-input',
        defaultOptions: {
            defaultValue: null,
            templateOptions: {
                type: 'number',
                inputmode: 'numeric'
            }
        }
    },
    {
        name: 'ta-decimal',
        extends: 'ta-input',
        defaultOptions: {
            defaultValue: null,
            templateOptions: {
                type: 'number',
                inputmode: 'decimal'
            }
        }
    },
    {
        name: 'ta-radio',
        component: TaRadioComponent,
        defaultOptions: {
            defaultValue: null,
            validation: {
                show: true
            },
            validators: {
                required: RequiredValidator,
            }
        },
        wrappers: ['ta-field-wrapper']
    },
    {
        name: 'ta-textarea',
        component: TaTextareaComponent,
        defaultOptions: {
            defaultValue: null,
            validators: {
                required: RequiredValidator,
            }
        },
        wrappers: ['ta-field-wrapper']
    },
    {
        name: 'ta-toggle',
        component: TaToggleComponent,
        defaultOptions: {
            defaultValue: null,
            validators: {
                required: RequiredValidator,
            }
        },
        wrappers: ['ta-field-wrapper']
    },
    {
        name: 'ta-uri',
        component: TaUriComponent,
        defaultOptions: {
            defaultValue: null,
            validators: {
                required: RequiredValidator,
                validateUri: UriValidator
            }
        },
        wrappers: ['ta-field-wrapper']
    }
];