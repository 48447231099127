export function PDFReportPlaceholder(backendEnabled: boolean, api?: string, reportType: 'standard' | 'custom' = 'custom'): string {
    let params = 'poweredByLogo, client, audit, assets, dayjs, images';
    if (api) {
        if (api === 'v1.1') {
            params = 'client, user, audit, assets, images, dayjs';
        } else if (api === 'v1.2') {
            if (backendEnabled) {
                params = 'report, reportParams, generateUtils';
            } else {
                params = 'report, audit, generateUtils';
            }
        }
    }
    return `return ${(api === 'v1.2') && 'async'} function convertToPDF(${params}) {

        //  This is a starter template code for generating PDF. You can use this template to build upon it.

        //
        // PDF Page Setup
        //

        ${(api === 'v1.2') && 'const { Database, PDF, Audit, Image, dayjs } = generateUtils;'}
        ${(api === 'v1.2' && reportType === 'custom') ? 'const client = (await Database.getDocument(report.client_id)).content;' : ''}

        ${reportType === 'custom' ?
            `
        let pdf = {
            pageSize: 'A4',
            content: [],
            info: {
                title: client.name,
                author: client.name,
                creator: client.name,
                producer: client.name
            },
            footer: {
                columns: [
                    {
                        text: 'Tel: ' + client.contact.phone_number + '   Email: ' + client.contact.email + '\\n' + client.address.address_line_1 + ', ' + (client.address.address_line_2 && client.address.address_line_2 + ', ') + client.address.city + ', ' + client.address.post_code + '.',
                        alignment: 'center',
                        fontSize: 8
                    }
                ]
            }
        };
        `:
            `
        let pdf = {
            pageSize: 'A4',
            content: [],
            info: {
                title: "TickAudit",
                author: "TickAudit",
                creator: "TickAudit",
                producer: "TickAudit",
            }
        };
        `}
       

        return {
            fileName: 'StarterPDF_' + dayjs().toISOString() + '.pdf',
            pdf
        };
    }`;
}