export function ExcelReportPlaceholder(backendEnabled: boolean, api?: string, reportType: 'standard' | 'custom' = 'custom'): string {
    let params = 'client, user, audit, assets, images, ExcelJS, dayjs';
    if (api) {
        if (api === 'v1.2') {
            if (backendEnabled) {
                params = 'wb, report, reportParams, generateUtils';
            } else {
                params = 'wb, report, audit, generateUtils';
            }
        }
    }

    return `return ${(api === 'v1.2') && 'async'} function wsGenerate(${params}) {

        //  This is a starter template code for generating Excel. You can use this template to build upon it.

        // Excel Setup

        ${(api === 'v1.2') && 'const { Database, Excel, Audit, Image, dayjs } = generateUtils;'}
        ${(api === 'v1.2' && reportType === 'custom') ? 'const client = (await Database.getDocument(report.client_id)).content;' : ''}

        ${(api !== 'v1.2') ? 'const wb = new ExcelJS.Workbook();' : ''}
        wb.creator = 'TickAudit';
        wb.lastModifiedBy = 'TickAudit';
        wb.created = new Date();
        wb.modified = new Date();
        wb.lastPrinted = new Date();

        const checksheet = wb.addWorksheet('Sheet 1');

        let fileName = 'StarterExcel_' + dayjs().toISOString() + '.xlsx';

        return {
            fileName,
            wb,
            exceljs: true
        };

    }`;

}