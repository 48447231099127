import { AuditSettings } from "@models/miscellaneous";

export class UpdateAuditSettings {
    static readonly type = '[Settings] Update Audit Settings';
    constructor(public settings: AuditSettings) { }
}

export class AddExportSettings {
    static readonly type = '[Settings] Add Export Settings';
    constructor(public reportId: string, public settings: Record<string, any>) { }
}

export class UpdateExportSettings {
    static readonly type = '[Settings] Update Export Settings';
    constructor(public reportId: string, public settings: Record<string, any>) { }
}

export class ResetSettingsState {
    static readonly type = '[Settings] Reset Settings State';
    constructor() { }
}