export function Bundles() {
    return [
        {
            name: 'None',
            value: 'none'
        },
        {
            name: 'Safety Netting',
            value: 'safety-netting'
        },
        {
            name: 'Scaffolding',
            value: 'scaffolding'
        }
    ]
}

export function MaxUsers() {
    const max = 10000;
    const step = 10;
    const maxUsers = [];

    for (let i = 1; i <= (max / step); i++) {
        maxUsers.push(step * i);
    }
    return maxUsers;
}

export function MaxAssets() {
    const max = 250000;
    const step = 250;
    const maxAssets = [];

    for (let i = 1; i <= (max / step); i++) {
        maxAssets.push(step * i);
    }
    return maxAssets;
}